export const HEADER_HEIGHT = 64;
export const BOX_BORDER_UNIT = 8;
export const BOX_BORDER_TYPES = { PADDING: "padding", MARGIN: "margin" };
export const MENU_TYPES = { SIDE_MENU: "sideMenu" };

export const STATUS_TYPES = { ON: "ON", OFF: "OFF" };
export const FORM_TYPES = {
  INPUT: "INPUT",
  INPUT_PASSWORD: "INPUT_PASSWORD",
  SELECT: "SELECT",
  DATE: "DATE",
  DATE_RANGE: "DATE_RANGE",
  INPUT_NUMBER: "INPUT_NUMBER",
  INPUT_TEXTAREA: "INPUT_TEXTAREA",
  CHECK_BOX: "CHECK_BOX",
  SWITCH: "SWITCH",
  SELECT_TAG: "SELECT_TAG",
  CUSTOM: "CUSTOM",
  INPUT_SEARCH: "INPUT_SEARCH"
};
export const ROLE_TYPES = { CLIENT: "client", AGENT: "agent" };
export const LOCALSTORAGE_TYPES = {
  TOKEN: "token",
  SIMPLYBOOK_ADMIN_TOKEN: "simplybookAdminToken",
  SIMPLYBOOK_USER_TOKEN: "simplybookUserToken",
  SIMPLYBOOK_COMPANY_TOKEN: "simplybookCompanyToken"
};

export const API_METHODS = {
  CREATE: "CREATE",
  DELETE: "DELETE",
  UPDATE: "UPDATE",
  BULK_CREATE: "BULK_CREATE",
  BULK_DELETE: "BULK_DELETE",
  BULK_UPDATE: "BULK_UPDATE"
};

export const TAG_TYPES = { PROVIDER_SORT: "PROVIDER_SORT", LINE_PROVIDER: "LINE_PROVIDER" };
export const AUTH_TYPES = {
  TAIHEVILLAGE: "TAIHEVILLAGE",
  FEET_TAIHEVILLAGE: "FEET_TAIHEVILLAGE",
  OTHER: "OTHER"
};
export const DEFAULT_PAGINTAION = { page: 1, size: 10, total: 0 };
export const AUTH_TYPE_KV = { TAIHEVILLAGE: "泰和村", FEET_TAIHEVILLAGE: "怡然富足", OTHER: "其他" };

export const ROLES = {
  COUPON: "coupons",
  SCHEDULE: "schedule"
};

export const SALES_PLUGIN = {
  UPDATE: "update",
  EXPORT: "export",
  MORNING: "morning",
  NIGHT: "night",
  ADMIN: "admin"
};
export const PROVIDERS_PLUGIN = {
  UPDATE: "update",
  EXPORT: "export",
  AUTH: "auth",
  SORT: "sort"
};
export const COUPONS_PLUGIN = {
  UPDATE: "update",
  ADD: "add",
  DELETE: "delete"
};
export const PLUGSINS = {
  COUPON: "coupons",
  PROVIDER: "providers",
  SALE: "sales"
};

export const SORT = {
  desc: "descend",
  asc: "ascend",
  descend: "desc",
  ascend: "asc"
};
