import { CircularProgress } from "@mui/material";

import LoadingStyle from "./styled";
function Loading({ loading }) {
  return loading ? (
    <LoadingStyle>
      <CircularProgress />
    </LoadingStyle>
  ) : null;
}
export default Loading;
