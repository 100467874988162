import { Suspense } from "react";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";

import Loading from "components/Loading";
import PageNotFound from "components/PageNotFound";
import Container from "layout/Container";

import LoadingProvider from "provider/Loading";
import MenuProvider from "provider/Menu";
import { store } from "store/createStore";

import { mainRoutes } from "./routes";
import { gaTrackerId } from "config/envConfig";

import "./index.css";
import "./styles/markdown.css";

if (gaTrackerId) ReactGA.initialize(gaTrackerId, { titleCase: false });

function RenderRoutes() {
  return (
    <Routes>
      {mainRoutes.map((route) =>
        route.children ? (
          route.children.map((child) => (
            <Route key={child.path} {...child} element={<Container>{child.element}</Container>} />
          ))
        ) : (
          <Route key={route.path} {...route} element={<Container>{route.element}</Container>} />
        )
      )}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <LoadingProvider>
            <MenuProvider>
              <RenderRoutes />
            </MenuProvider>
          </LoadingProvider>
        </BrowserRouter>
      </Suspense>
    </Provider>
  );
}

export default App;
