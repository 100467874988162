import React from "react";
import { Box, Link, TableHead, TableRow, Typography, Grid } from "@mui/material";

import Logo from "assets/logo-header.png";
import styles from "styles/footer.module.css";

const Footer = () => {
  return (
    <footer>
      <Grid
        container
        spacing={[2, 0]}
        className={styles.container}
        sx={{ padding: { sm: "32px 32px 32px 48px", xs: "0px 32px 32px 48px" } }}
      >
        <Grid item md={3} sm={4} xs={12} sx={{ textAlign: { xs: "center", sm: "start" } }}>
          <Link to="/">
            <img src={Logo} alt="logo" height={"60px"} />
          </Link>
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <Typography variant="subtitle1" whiteSpace="break-spaces" color="#434F6D">
            {
              "電話：04-22789599\n手機：0985-781325\n傳真：04-22782938\n統編：60292889\n信箱：shseed01@gmail.com\n地址：台中市太平區成功路19-1號"
            }
          </Typography>
        </Grid>

        <Grid alignContent="end" item md={6} sm={4} xs={12} sx={{ textAlign: { sm: "end" } }}>
          <Typography color="#434F6D" variant="subtitle1">
            採光罩工程 玻璃屋工程 台中防盜門窗工程
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
