import { cloneDeep, map } from "lodash";
import { NEWS_ACTIONS as actions } from "../actions";
import { initialState } from "../initialState";
import { createReducer, handleState } from "../utils";

const { news } = initialState;
const clearCurrent = (state) => ({ ...state, current: {} });

const setList = (state, { data }) => {
  const newData = cloneDeep(data);
  delete newData.rows;
  return { ...state, list: data.rows, ...newData };
};

const setCurrent = (state, { data }) => {
  return { ...state, current: data };
};

const actionList = (state, { data, method }) => {
  const stateIds = map(state.list, "id");
  const cloneData = cloneDeep(data);
  const _filterData = cloneData.rows.filter(({ id }) => !stateIds.includes(id));
  const newData = handleState(state.list, cloneDeep(_filterData), method);
  delete cloneData.rows;
  return { ...state, list: newData, ...cloneData };
};

export const newsReducer = createReducer(news, {
  [actions.CLEAR_CURRENT]: clearCurrent,
  [actions.SET_LIST]: setList,
  [actions.SET_CURRENT]: setCurrent,
  [actions.CUD_LIST]: actionList
});
