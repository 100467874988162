import { useEffect } from "react";

import { sendGaView } from "trackers/ga";

export default function WithTracker({ children, title }) {
  useEffect(() => {
    sendGaView({ title });
  }, [title]);

  return <>{children}</>;
}
