import { cloneDeep } from "lodash";
import { SUBCATEGORY_ACTIONS as actions } from "../actions";
import { initialState } from "../initialState";
import { createReducer, handleState } from "../utils";

const { subcategory } = initialState;
const clearCurrent = (state) => ({ ...state, current: {} });

const setList = (state, { data }) => {
  const newData = cloneDeep(data);
  delete newData.rows;
  return { ...state, list: data.rows, ...newData };
};

const setCurrent = (state, { data }) => {
  return { ...state, current: data };
};

const actionList = (state, { data, method }) => {
  const newData = handleState(state.list, cloneDeep(data), method);
  return { ...state, list: newData };
};

export const subcategoryReducer = createReducer(subcategory, {
  [actions.CLEAR_CURRENT]: clearCurrent,
  [actions.SET_LIST]: setList,
  [actions.SET_CURRENT]: setCurrent,
  [actions.CUD_LIST]: actionList
});
