"use client";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Box,
  Drawer,
  List,
  ListItemText,
  ListItemButton,
  ListItem,
  Link,
  Toolbar,
  AppBar,
  Grid,
  Typography
} from "@mui/material";

import Logo from "assets/logo-header.png";
import FbLogo from "assets/fb-icon.png";
import LineLogo from "assets/line-icon.png";
import { mainRoutes } from "routes";
import styles from "styles/header.module.css";
import { useNavigate } from "react-router-dom";
import { BorderBottom } from "@mui/icons-material";

const Header = (props) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigator = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const getSx = (path) =>
    window.location.pathname === path ? { fontWeight: 600, borderBottom: "2px solid #FDBA35" } : "";

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Box sx={{ flexGrow: 5, textAlign: "end", display: { sm: "none" } }}>
        <IconButton
          color="inherit"
          aria-label="close drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ "& svg": { fill: "black" } }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <List>
        {mainRoutes
          .filter(({ display }) => !!display)
          .map(({ path, label }) => (
            <ListItem key={path} disablePadding>
              <ListItemButton component="a" to={path}>
                <ListItemText primary={label} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Box>
  );

  const goPath = (path) => {
    navigator(path);
  };

  return (
    <>
      <AppBar component="nav" className={styles.container}>
        <Toolbar>
          <Link to="/">
            <Box sx={{ flexGrow: 1 }}>
              <img height="60px" src={Logo} alt="logo" />
            </Box>
          </Link>

          <Box sx={{ flexGrow: 5, textAlign: "end", display: { sm: "none" } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ "& svg": { fill: "black" } }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box p={4} sx={{ flexGrow: 4, display: { xs: "none", sm: "block" } }}>
            <Grid sx={{ flexGrow: 1 }} container spacing={4}>
              {mainRoutes
                .filter(({ display }) => !!display)
                .map(({ path, label }) => (
                  <Grid sx={{ color: "black", cursor: "pointer" }} key={path} item onClick={() => goPath(path)}>
                    <Typography color="#434F6D" sx={getSx(path)}>
                      {label}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
            <Grid container spacing={2} justifyContent={"end"}>
              <Grid item onClick={() => window.open("https://www.facebook.com/daiyoung0422789599/?locale=zh_TW")}>
                <img src={FbLogo} width="26px" height="26px" alt="fb-logo" />
              </Grid>
              <Grid item onClick={() => window.open("https://lin.ee/J7FzxTg")}>
                <img src={LineLogo} width="26px" height="26px" alt="line-logo" />
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          anchor="right"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "70vw"
            }
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
};

export default Header;
