import styled from "styled-components";
import { BOX_BORDER_UNIT } from "utils/define";

export default styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 1101;
  top: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  padding: ${BOX_BORDER_UNIT * 4}px;
`;
