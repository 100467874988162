import WithTracker from "components/Tracker";
import { lazy } from "react";

const ContactUsPage = lazy(() => import("pages/ContactUsPage"));
const NewsPage = lazy(() => import("pages/NewsPage"));
const NewsDetailPage = lazy(() => import("pages/NewsDetailPage"));
const PiecePage = lazy(() => import("pages/PiecePage"));
const PieceDetailPage = lazy(() => import("pages/PieceDetailPage"));
const ServicePage = lazy(() => import("pages/ServicePage"));
const HomePage = lazy(() => import("pages/HomePage"));

export const mainRoutes = [
  {
    icon: "",
    label: "首頁",
    display: true,
    exact: true,
    path: "/",
    element: (
      <WithTracker title="首頁">
        <HomePage />
      </WithTracker>
    )
  },
  {
    icon: "",
    label: "最新消息",
    display: true,
    exact: true,
    path: "/news",
    element: (
      <WithTracker title="最新消息">
        <NewsPage />
      </WithTracker>
    )
  },
  {
    icon: "",
    label: "",
    exact: true,
    path: "/news-detail/:id",
    element: (
      <WithTracker title="最新消息詳細頁">
        <NewsDetailPage />
      </WithTracker>
    )
  },
  {
    icon: "",
    display: true,
    label: "岱詠作品",
    exact: true,
    path: "/piece",
    element: (
      <WithTracker title="岱詠作品">
        <PiecePage />
      </WithTracker>
    )
  },
  {
    icon: "",
    label: "",
    exact: true,
    path: "/piece-detail/:id",
    element: (
      <WithTracker title="岱詠作品詳細頁">
        <PieceDetailPage />
      </WithTracker>
    )
  },
  {
    icon: "",
    label: "岱詠的服務",
    exact: true,
    display: true,
    path: "/service",
    element: (
      <WithTracker title="岱詠的服務">
        <ServicePage />
      </WithTracker>
    )
  },
  {
    icon: "",
    label: "關於我們",
    exact: true,
    display: true,
    path: "/contact-us",
    element: (
      <WithTracker title="關於我們">
        <ContactUsPage />
      </WithTracker>
    )
  }
];
