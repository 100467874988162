import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function PageNotFound() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        background: "white",
        textAlign: "center"
      }}
    >
      <Box>
        <Typography variant="h1" color="black">
          404
        </Typography>
        <div />
        <Link to="/">
          <Button variant="primary" sx={{ background: "black" }}>
            回到首頁
          </Button>
        </Link>
      </Box>
    </Box>
  );
}
export default PageNotFound;
