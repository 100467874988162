import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// const roboto = Roboto({
//   weight: ["300", "400", "500", "700"],
//   subsets: ["latin"],
//   display: "swap",
// });

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: { color: "black" }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: { background: "#FDA802", color: grey[900], borderRadius: 0 }
        // ({ theme }) => ({
        //   color:
        //     theme.palette.primary.mainButton === "light" ? "#fff" : theme.palette.primary.main,
        // }),
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#656E84",
          fontWeight: 400,
          "&.Mui-selected ": { color: "#FDA802" }
        }
      }
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          "& .MuiButtonBase-root": {
            color: "#434F6D"
          },
          "& .MuiButtonBase-root.Mui-selected": {
            borderBottom: "2px solid #727B91",
            borderRadius: 0,
            background: "transparent"
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: { width: "0 !important" },
        root: {
          "&.MuiTabs-vertical": { background: "white", borderRadius: "20px", border: "2px solid rgba(0,0,0,0.2)" }
        }
      }
    }
  },
  typography: {
    fontSize: 17,
    fontFamily: "'Noto Sans TC'",
    h1: {
      fontSize: "2.4rem",
      fontWeight: 700
    },
    h2: {
      fontSize: "2rem"
    },
    h3: {
      fontSize: "1.6rem"
    },
    h4: {
      fontSize: "1.4rem"
    },
    h5: {
      fontSize: "1.2rem"
    },
    h6: {
      fontSize: "1.15rem",
      fontWeight: 300
    },
    subtitle1: {
      fontSize: "1.2rem"
    }
  }
});

export default theme;
