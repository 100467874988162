import { API_METHODS, LOCALSTORAGE_TYPES } from "utils/define";

export const createReducer = (initialState, handlers) => {
  return (state = initialState, action) => {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
};

export const getDefaultHeaders = () => {
  const token = window.localStorage.getItem(LOCALSTORAGE_TYPES.TOKEN);
  return {
    "Content-Type": "application/json",
    "X-Access-Token": token
  };
};

export function getQuery(url, params) {
  const query = Object.entries(params)
    .reduce((string, [key, value]) => {
      return string + `${key}=${value}&`;
    }, "")
    .slice(0, -1);
  return url.includes("?") ? query : "?" + query;
}

export function handleState(state, data, method) {
  switch (method) {
    case API_METHODS.CREATE: {
      state.push(data);
      return state;
    }
    case API_METHODS.BULK_CREATE:
      return state.concat(data);
    case API_METHODS.DELETE:
      return state.filter(({ id }) => id !== data);
    case API_METHODS.BULK_DELETE:
      return state.filter(({ id }) => !data.find((d) => d.id === id));
    case API_METHODS.UPDATE:
      return state.reduce((arr, stateValue) => {
        if (stateValue.id === data.id) arr.push({ ...stateValue, ...data });
        else arr.push(stateValue);
        return arr;
      }, []);
    case API_METHODS.BULK_UPDATE:
      return state.reduce((arr, stateValue) => {
        const currentUpdateDate = data.find(({ id }) => stateValue.id === id);
        if (currentUpdateDate) arr.push({ ...stateValue, ...currentUpdateDate });
        else arr.push(stateValue);
        return arr;
      }, []);
  }
}

export function unAutherizeDirect() {
  const isLoginPage = window.location.pathname === "/login";
  window.localStorage.removeItem(LOCALSTORAGE_TYPES.TOKEN);
  if (!isLoginPage) window.location.href = "/login";
}
