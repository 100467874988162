import reduceReducers from "reduce-reducers";
import { combineReducers } from "redux";

import { initialState } from "../initialState";

import { categoryReducer } from "./category";
import { subcategoryReducer } from "./subcategory";
import { newsReducer } from "./news";
import { articleReducer } from "./article";

const rootReducer = combineReducers({
  article: articleReducer,
  category: categoryReducer,
  subcategory: subcategoryReducer,
  news: newsReducer
});

export default reduceReducers(initialState, rootReducer);
