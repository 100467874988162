import { get } from "lodash";
import ReactGA from "react-ga4";

import { GA_CATEGORY, GA_UTM_CAMPAIGN, GA_UTM_MEDIUM, GA_UTM_SOURCE } from "config/gaConfig";
import configs from "config/envConfig";

export const sendGAEvent = ({ action, category }, others = {}) => {
  console.log("ga", action, others);
  if (!action || !category) return;

  ReactGA.event({ category, action, value: 1, ...others });
};

export const sendGaView = ({ title }) => {
  console.log("ga pageview:", title);
  if (!title) return;
  const pageviewEvent = {
    hitType: "pageview",
    title,
    page: window.location.href || ""
  };
  console.log("pageview", pageviewEvent);
  ReactGA.send(pageviewEvent);
};
