import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import Header from "components/Header";
import Footer from "components/Footer";
import theme from "theme";

import styles from "styles/page.module.css";

function Container({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <section>
        <Header />
        <Box className={styles["layout"]} pt="6rem">
          {children}
        </Box>
        <Footer />
      </section>
    </ThemeProvider>
  );
}
export default Container;
